import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  testError: boolean = true;

  teamMembers: number = 5;

  isLinear: boolean = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private person_0: FormBuilder,
    private person_1: FormBuilder,
    private person_2: FormBuilder,
    private person_3: FormBuilder,
    private person_4: FormBuilder,
    private _app: AppComponent
  ) {
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      organization: [''],
      street: ['', Validators.required],
      postcode: ['', Validators.required],
      location: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
    });

    this.secondFormGroup = this._formBuilder.group({
      ageGroup: ['', Validators.required],
      teamName: ['', Validators.required],
      person_0: this._formBuilder.group({
        name: ['', Validators.required],
        age: ['', Validators.required],
        captain: [false],
      }),
      person_1: this._formBuilder.group({
        name: ['', Validators.required],
        age: ['', Validators.required],
        captain: [false],
      }),
      person_2: this._formBuilder.group({
        name: ['', Validators.required],
        age: ['', Validators.required],
        captain: [false],
      }),
      person_3: this._formBuilder.group({
        name: ['', Validators.required],
        age: ['', Validators.required],
        captain: [false],
      }),
      person_4: this._formBuilder.group({
        name: ['', Validators.required],
        age: ['', Validators.required],
        captain: [false],
      }),
    });

    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  setCaptian(event: MatSlideToggleChange): void {
    console.log(event);
  }

  openPrivacyPolicy(): void {
    this._app.openDrawer('Privacy');
  }
}
