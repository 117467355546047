<div class="wrapper">
  <h1>Sponsoren</h1>
  <mat-grid-list [cols]="cols" gutterSize="10" rowHeight="1:0.75">
    <mat-grid-tile
      *ngFor="let tile of tiles"
      [colspan]="tile.cols"
      [rowspan]="tile.rows"
      (click)="openUrl(tile.url)"
    >
      <img
        *ngIf="tile.img; else yours"
        src="../assets/img/{{ tile.img }}"
        alt="{{ tile.alt }}"
      />
      <ng-template #yours>
        <div class="yours-ad">
          <div class="ad">Hier könnte Eure<br />Werbung stehen</div>
          <div class="ad">📣</div>
        </div>
      </ng-template>
    </mat-grid-tile>
  </mat-grid-list>
</div>
