import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ComponentsEnum } from './components/component';
import { CookieService } from './services/cookie.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('drawer') drawer!: MatDrawer;
  hasAnalyticsCookie: boolean = false;

  currentDate: string = moment().format('DD.MM.yyyy');

  isCookieNotification: boolean = false;
  isPrivacyPolicy: boolean = false;
  isImprint: boolean = false;
  isDisabledDrawer: boolean = true;
  isTranspancy: boolean = false;

  constructor(
    private translate: TranslateService,
    private title: Title,
    private _bottomSheet: MatBottomSheet,
    private _cookie: CookieService
  ) {}

  ngOnInit(): void {
    this.title.setTitle(
      'Bettenrennen Rötgesbüttel | Cultura Rotlekesbutle 1887 e. V.'
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkCookie();
    }, 0);
  }

  checkCookie(): void {
    !this._cookie.checkCookie('_cookieNotificationDisplayed') &&
      this.openDrawer('Cookie');
  }

  openDrawer(component: string): void {
    ComponentsEnum.COOKIE === component &&
      ((this.isCookieNotification = true),
      (this.isPrivacyPolicy = false),
      (this.isImprint = false),
      (this.isDisabledDrawer = true),
      (this.isTranspancy = false),
      this.drawer.open());
    ComponentsEnum.PRIVACY === component &&
      ((this.isCookieNotification = false),
      (this.isPrivacyPolicy = true),
      (this.isImprint = false),
      (this.isDisabledDrawer = false),
      (this.isTranspancy = false),
      this.drawer.open());
    ComponentsEnum.IMPRINT === component &&
      ((this.isCookieNotification = false),
      (this.isPrivacyPolicy = false),
      (this.isImprint = true),
      (this.isDisabledDrawer = false),
      (this.isTranspancy = false),
      this.drawer.open());
    ComponentsEnum.TRANSPANCY === component &&
      ((this.isCookieNotification = false),
      (this.isPrivacyPolicy = false),
      (this.isImprint = false),
      (this.isDisabledDrawer = false),
      (this.isTranspancy = true),
      this.drawer.open());
  }

  drawerClosed(): void {
    this.isCookieNotification = false;
    this.isPrivacyPolicy = false;
    this.isImprint = false;
    this.isTranspancy = false;
    setTimeout(() => {
      const cookie = this._cookie.cookieStringToBoolean(
        this._cookie.getCookie('_analytics')
      );
      this.hasAnalyticsCookie = cookie;
    }, 0);
  }
}
