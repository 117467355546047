import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  team: string;
  optics: number;
  time: string;
}

const TIME_DATA: PeriodicElement[] = [
  { team: 'Splash', optics: 327, time: '25:43' },
  { team: 'Capitääne', optics: 464.8, time: '25:43' },
  { team: 'Who let the dogs out', optics: 491.5, time: '26:00' },
  { team: 'Golden Girls auf Verjüngungskur', optics: 420.5, time: '32:42' },
  { team: 'Jubiläumsbett Feuerwehr', optics: 340, time: '31:14' },
  { team: 'Peaky Blinders', optics: 433.5, time: '19:04' },
  { team: 'Baden wie zu Kaisers Zeiten', optics: 437.5, time: '18:36' },
  { team: 'Ghost Busters', optics: 419, time: '16:35' },
  { team: 'Milchburschen', optics: 432, time: '16:57' },
];

const OPTICS_DATA: PeriodicElement[] = [
  { team: 'Splash', optics: 327, time: '25:43' },
  { team: 'Capitääne', optics: 464.8, time: '25:43' },
  { team: 'Who let the dogs out', optics: 491.5, time: '26:00' },
  { team: 'Golden Girls auf Verjüngungskur', optics: 420.5, time: '32:42' },
  { team: 'Jubiläumsbett Feuerwehr', optics: 340, time: '31:14' },
  { team: 'Peaky Blinders', optics: 433.5, time: '19:04' },
  { team: 'Baden wie zu Kaisers Zeiten', optics: 437.5, time: '18:36' },
  { team: 'Ghost Busters', optics: 419, time: '16:35' },
  { team: 'Milchburschen', optics: 432, time: '16:57' },
];

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  breakpoint: number = 2;
  rowHeight: string = '1:1';

  timeColumns: string[] = ['id', 'team', 'time'];
  opticsColumns: string[] = ['id', 'team', 'optics'];
  opticsData = OPTICS_DATA;
  timeData = TIME_DATA;

  constructor() {}

  ngOnInit(): void {
    this._sortByTime();
    this._sortByOptics();

    if (window.innerWidth <= 1024) {
      this.breakpoint = window.innerWidth <= 1024 ? 1 : 2;
      this.rowHeight = '1:1.8';
    }
  }

  private _sortByOptics(): void {
    this.opticsData = this.opticsData.sort((a, b) => b.optics - a.optics);
  }

  private _sortByTime(): void {
    this.timeData = this.timeData.sort((c, d) =>
      c.time > d.time ? 1 : d.time > c.time ? -1 : 0
    );
  }
}
