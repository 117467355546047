<div class="wrapper" [ngClass]="isHidden ? 'out' : 'in'">
  <div class="column-1">
    <div
      class="birdy"
      [ngClass]="isHidden ? 'out' : 'in'"
      matRipple
      (click)="toggleState()"
    >
      <img
        *ngIf="isHidden; else close"
        src="./assets/img/twitter_single.png"
        alt="🐦"
      />
      <ng-template #close>
        <mat-icon>close</mat-icon>
      </ng-template>
    </div>
  </div>
  <div class="timeline">
    <app-ngx-twitter-timeline
      *ngIf="app.hasAnalyticsCookie; else blocker"
    ></app-ngx-twitter-timeline>
    <ng-template #blocker>
      <div class="cookie-wrapper">
        <h1>
          Wir möchten Dir gerne den vollen Umfang unserer Webseiten zeigen
        </h1>
        <p>
          Wir möchten Dir gerne unsere {{ applicationName }}-Inhalte anzeigen.
          Diese werden allerdings aufgrund Deiner aktuellen Cookie-Einstellungen
          blockiert. Klick auf “Cookies aktivieren”, um unseren
          Datenschutzbestimmungen zuzustimmen und die
          {{ applicationName }}-Inhalte anzuzeigen. Weitere Informationen
          erhältst Du in den
          <a
            class="third-party-url"
            href="{{ privacyThirdPartyUrl }}"
            target="_blank"
            >Datenschutzbestimmungen</a
          >
          von {{ applicationName }}. Du kannst der Nutzung dieser Cookies
          jederzeit in unseren Cookie-Einstellungen widersprechen.
        </p>
        <button mat-flat-button (click)="activateCookies()">
          <mat-icon>settings</mat-icon> Cookies aktivieren
        </button>
      </div>
    </ng-template>
  </div>
</div>
