<div class="wrapper" *ngIf="!inEvent && !afterEvent">
  <div class="item-wrapper">
    <div class="item">{{ rest[0] }}</div>
    <label>Tag(en)</label>
  </div>
  <div class="item-wrapper">
    <div class="item">{{ rest[1] }}</div>
    <label>Stunde(n)</label>
  </div>
  <div class="item-wrapper">
    <div class="item">{{ rest[2] }}</div>
    <label>Minute(n)</label>
  </div>
  <div class="item-wrapper">
    <div class="item">{{ rest[3] }}</div>
    <label>Sekunde(n)</label>
  </div>
</div>

<!-- <div class="item event" *ngIf="inEvent">
  Der Livestream des Bettenrennen 2023 findet in der Zeit von 15.00 bis 22.00
  Uhr statt.
</div> -->

<div class="item after-event" *ngIf="afterEvent">
  Das Bettenrennen 2025 ist beendet.<br />Bis zum nächsten Mal :)
</div>
