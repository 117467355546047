<div class="section">
  <h1>{{ "REGISTRATION.HEADLINE" | translate }}</h1>
  <div class="wrapper">
    <mat-horizontal-stepper class="stepper" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>{{
            "REGISTRATION.CONTACT_PERSON.LABEL" | translate
          }}</ng-template>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.CONTACT_PERSON.NAME" | translate
            }}</mat-label>
            <input matInput formControlName="name" required />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.CONTACT_PERSON.ORGANIZATION" | translate
            }}</mat-label>
            <input matInput formControlName="organization" />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.CONTACT_PERSON.STREET" | translate
            }}</mat-label>
            <input matInput formControlName="street" required />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.CONTACT_PERSON.POSTCODE" | translate
            }}</mat-label>
            <input matInput formControlName="postcode" required />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.CONTACT_PERSON.LOCATION" | translate
            }}</mat-label>
            <input matInput formControlName="location" required />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.CONTACT_PERSON.PHONE" | translate
            }}</mat-label>
            <input matInput formControlName="phone" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.CONTACT_PERSON.EMAIL" | translate
            }}</mat-label>
            <input matInput formControlName="email" required />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <div>
            <button mat-button matStepperNext>
              {{ "BTN.NEXT" | translate }}
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>{{
            "REGISTRATION.TEAM.LABEL" | translate
          }}</ng-template>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.TEAM.TEAM_NAME" | translate
            }}</mat-label>
            <input matInput formControlName="teamName" required />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{
              "REGISTRATION.TEAM.AGE_GROUP" | translate
            }}</mat-label>
            <input matInput formControlName="ageGroup" required />
            <mat-error *ngIf="testError">{{
              "NOTIFICATIONS.FIELD_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>

          <h3>{{ "REGISTRATION.TEAM.TEAM_MEMBERS.HEADLINE" | translate }}</h3>
          <div class="team-member">
            <h4>{{ "REGISTRATION.TEAM.PERSON.ONE" | translate }}</h4>
            <div class="member" formGroupName="person_0">
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.NAME" | translate
                }}</mat-label>
                <input matInput formControlName="name" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.AGE" | translate
                }}</mat-label>
                <input matInput type="number" formControlName="age" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-slide-toggle
                (change)="setCaptian($event)"
              ></mat-slide-toggle>
            </div>
            <h4>{{ "REGISTRATION.TEAM.PERSON.TWO" | translate }}</h4>
            <div class="member" formGroupName="person_1">
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.NAME" | translate
                }}</mat-label>
                <input matInput formControlName="name" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.AGE" | translate
                }}</mat-label>
                <input matInput type="number" formControlName="age" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-slide-toggle
                (change)="setCaptian($event)"
              ></mat-slide-toggle>
            </div>
            <h4>{{ "REGISTRATION.TEAM.PERSON.THREE" | translate }}</h4>
            <div class="member" formGroupName="person_2">
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.NAME" | translate
                }}</mat-label>
                <input matInput formControlName="name" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.AGE" | translate
                }}</mat-label>
                <input matInput type="number" formControlName="age" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-slide-toggle
                (change)="setCaptian($event)"
              ></mat-slide-toggle>
            </div>
            <h4>{{ "REGISTRATION.TEAM.PERSON.FOUR" | translate }}</h4>
            <div class="member" formGroupName="person_3">
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.NAME" | translate
                }}</mat-label>
                <input matInput formControlName="name" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.AGE" | translate
                }}</mat-label>
                <input matInput type="number" formControlName="age" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-slide-toggle
                (change)="setCaptian($event)"
              ></mat-slide-toggle>
            </div>
            <h4>{{ "REGISTRATION.TEAM.PERSON.FIVE" | translate }}</h4>
            <div class="member" formGroupName="person_4">
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.NAME" | translate
                }}</mat-label>
                <input matInput formControlName="name" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{
                  "REGISTRATION.TEAM.PERSON.AGE" | translate
                }}</mat-label>
                <input matInput type="number" formControlName="age" required />
                <mat-error *ngIf="testError">{{
                  "NOTIFICATIONS.FIELD_REQUIRED" | translate
                }}</mat-error>
              </mat-form-field>
              <mat-slide-toggle
                (change)="setCaptian($event)"
              ></mat-slide-toggle>
            </div>
          </div>

          <div>
            <button mat-button matStepperPrevious>
              {{ "BTN.BACK" | translate }}
            </button>
            <button mat-button matStepperNext>
              {{ "BTN.NEXT" | translate }}
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
          <ng-template matStepLabel>{{
            "REGISTRATION.FINISH.LABEL" | translate
          }}</ng-template>
          <h2>
            {{ "REGISTRATION.FINISH.POLICIES.DECLARATION.TITLE" | translate }}
          </h2>
          <h3>
            {{
              "REGISTRATION.FINISH.POLICIES.DECLARATION.NOTIFICATION"
                | translate
            }}
          </h3>
          <ul>
            <li>
              {{
                "REGISTRATION.FINISH.POLICIES.DECLARATION.ITEM_0" | translate
              }}
            </li>
            <li>
              {{
                "REGISTRATION.FINISH.POLICIES.DECLARATION.ITEM_1" | translate
              }}
            </li>
            <li>
              {{
                "REGISTRATION.FINISH.POLICIES.DECLARATION.ITEM_3" | translate
              }}
            </li>
            <li>
              {{
                "REGISTRATION.FINISH.POLICIES.DECLARATION.ITEM_2" | translate
              }}
            </li>
            <a href="https://muttizettel.net/muttizettel.pdf" target="_blank">
              {{ "REGISTRATION.FINISH.POLICIES.DECLARATION.MUTTI" | translate }}
            </a>
          </ul>
          <div class="checkbox-label">
            <mat-checkbox></mat-checkbox>
            <label
              >{{ "REGISTRATION.FINISH.ACCEPT_DECLARATIONS" | translate }}
            </label>
          </div>
          <div class="spacer"></div>
          <h2>
            {{ "REGISTRATION.FINISH.POLICIES.PRIVACY_POLICY" | translate }}
          </h2>
          <div class="checkbox-label">
            <mat-checkbox></mat-checkbox>
            <label
              >{{ "REGISTRATION.FINISH.ACCEPT_PRIVACY_POLICY_0" | translate }}
              <span class="span-link" (click)="openPrivacyPolicy()">{{
                "REGISTRATION.FINISH.ACCEPT_PRIVACY_POLICY_1" | translate
              }}</span>
              {{
                "REGISTRATION.FINISH.ACCEPT_PRIVACY_POLICY_2" | translate
              }}</label
            >
          </div>
          <div class="spacer"></div>
          <button mat-button matStepperPrevious>
            {{ "BTN.BACK" | translate }}
          </button>
          <button mat-button matStepperNext>
            {{ "BTN.NEXT" | translate }}
          </button>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
