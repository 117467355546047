<div class="wrapper">
  <h1>Cultura Rotlekesbutle 1887 e. V.</h1>
  <img
    src="../../../assets/img/panorama.jpg"
    style="width: 100%; border-radius: 5px"
  />
  <h2>Das sind wir</h2>
  <p>
    Wir möchten, dass Rötgesbüttel noch lebenswerter wird. Unser Verein versteht
    sich als moderner Orts- und Kulturverein für den Ort Rötgesbüttel (früherer
    Name: Rotlekesbutle). Der Verein ist im Jahre 2022 aus dem 1887 gegründeten
    Männergesangverein hervorgegangen und als gemeinnützig anerkannt. Der Verein
    fördert u. a. die Kunst- und Kultur, sowie Ortsverschönerung und
    Heimatkunde. Eine kleine Gruppe rund um den ursprünglichen Verein und dem
    Organisationsteam des Bettenrennens hat die Initiative ergriffen, die
    formalen Voraussetzungen zu schaffen, die steuerlichen und
    gesellschaftsrechtlichen Vorteile, Vergünstigungen und Fördermöglichkeiten
    für den Ort und seine Menschen zu nutzen.
  </p>
  <P
    >Der Verein ist für alle offen, die mitentscheiden, mitgestalten oder
    einfach nur dabei sein wollen. Es gibt neben der Mitgliedschaftsgebühr von
    12€ pro Jahr (Personen unter 25 Jahre sind davon befreit) keine weiteren
    Verpflichtungen. Die <i>Satzung</i> findet ihr
    <a href="../../../assets/docs/Satzung.pdf" target="_blank">hier</a>, der
    <i>Mitgliedschaftsantrag</i> kann direkt
    <a href="http://mitglied-werden.cr1887.de/" target="_blank">hier</a>
    gestellt werden. Wir freuen uns über alle, die uns unterstützen.</P
  >
  <h2>Das machen wir</h2>
  <div class="row">
    <div class="col">
      <p>
        Die Gruppe der aktiven <i>Sängerinnen und Sänger</i> trifft sich
        regelmäßig seit 1887. Aktuell alle 14 Tage in der geraden Kalenderwoche
        am Dienstag um 18:00 Uhr auf dem Campingplatz Glockenheide, begleitet
        von der professionellen Chorleiterin Barbara König. Komm gerne zu uns
        und mach mit!
      </p>
      <p>
        Wir organisieren für euch das <i>Bettenrennen</i> in Rötgesbüttel am
        24.06.2023. Du kannst mitmachen: An den Spielstationen, im Ausschank,
        mit einem Bett oder einen eigenen Stand. Wenn du Interesse hast, dich in
        die Organisation einzubringen oder mitzugestalten melde dich gerne bei
        uns. Wir freuen uns!
      </p>
      <p>
        Mit den Einnahmen ist geplant, Projekte in und für den Ort Rötgesbüttel
        zu finanzieren und umzusetzen. Folgendes können wir uns darunter
        vorstellen: Eine Grillhütte, Sportgeräte, Musikunterricht für Kinder und
        Jugendliche, Freizeitmöglichkeiten für alle Altersgruppen. Mit unserer
        überarbeiteten Satzung haben wir die Voraussetzung geschaffen, für diese
        Projekte Fördermittel zu beantragen sowie Spendenbescheinigungen für
        mögliche Unterstützer auszustellen. Wenn du eine Idee hast, von der
        möglichst viele in Rötgesbüttel profitieren, melde dich bei uns - nur so
        können wir dich unterstützen.
      </p>
      <p>
        Ihr erreicht uns zu allen Themen unter
        <a href="mailto:cultura@rotlekesbutle.de">cultura@rotlekesbutle.de</a>
        sowie per WhatsApp unter
        <a href="https://wa.me/+4915153041887">0151.&nbsp;5304&nbsp;1887</a>.
      </p>
    </div>
    <div class="col"></div>
  </div>
  <!-- <h2>Die Gesichter</h2> -->
</div>
