import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
})
export class YoutubeComponent implements OnInit {
  applicationName: string = 'Youtube';
  privacyThirdPartyUrl: string = 'https://policies.google.com/privacy?hl=de';

  constructor(
    private _cookieService: CookieService,
    public app: AppComponent
  ) {}

  ngOnInit(): void {
    this.app.hasAnalyticsCookie = this._cookieService.cookieStringToBoolean(
      this._cookieService.getCookie('_analytics')
    );
  }

  activateCookies(): void {
    this._cookieService.setCookie('_analytics', 'true');
    this.app.hasAnalyticsCookie = true;
  }

  openYoutube(): void {
    const win = window.open(
      'https://www.youtube.com/watch?v=Al8dpnPTVRQ',
      '_blank'
    );
  }
}
