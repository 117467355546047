<!-- <video
  id="video"
  loop
  autoplay
  oncanplay="this.play()"
  onloadedmetadata="this.muted = true"
>
  <source src="./assets/vid/mock.mp4" type="video/mp4" />
  <img src="" alt="Hintergrundbild" />
</video> -->

<div class="bg"></div>

<div class="interruptor">Sa, 21.06.2025<br />ab 15:00 Uhr</div>

<div class="wrapper">
  <div class="title-wrapper">
    <div class="rule"></div>
    <div class="title">Bettenrennen<br />Rötgesbüttel</div>
    <div class="rule"></div>
  </div>
  <div class="countdown">Das Bettenrennen 2025 startet in</div>
  <app-countdown></app-countdown>
</div>
