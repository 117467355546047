<div class="wrapper">
  <h1>Rennergebnisse 2023</h1>
  <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" gutterSize="50">
    <mat-grid-tile>
      <div class="tile-wrapper">
        <h2>Schnellstes Bett</h2>
        <table mat-table class="time-table" [dataSource]="timeData">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Platz</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef>Team</th>
            <td mat-cell *matCellDef="let element">{{ element.team }}</td>
          </ng-container>

          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>Zeit</th>
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="timeColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: timeColumns"></tr>
        </table>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="tile-wrapper">
        <h2>Schönstes Bett</h2>
        <table mat-table class="optics-table" [dataSource]="opticsData">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Platz</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef>Team</th>
            <td mat-cell *matCellDef="let element">{{ element.team }}</td>
          </ng-container>

          <ng-container matColumnDef="optics">
            <th mat-header-cell *matHeaderCellDef>Jurybewertung</th>
            <td mat-cell *matCellDef="let element">
              {{ element.optics | number : "1.1" }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="opticsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: opticsColumns"></tr>
        </table>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
