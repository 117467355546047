<div class="wrapper">
  <div class="item-wrapper">
    <mat-icon>groups</mat-icon>
    <h2>Team anmelden</h2>
  </div>
  <div class="item-wrapper">
    <mat-icon>handyman</mat-icon>
    <h2>Bett bauen</h2>
  </div>
  <div class="item-wrapper">
    <mat-icon>celebration</mat-icon>
    <h2>Gemeinsam feiern</h2>
  </div>
  <div class="item-wrapper">
    <mat-icon>masks</mat-icon>
    <h2>Hygieneregeln beachten</h2>
  </div>
</div>
