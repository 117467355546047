import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss'],
})
export class InvitationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goToDownloads(): void {
    window.location.href = '#downloads';
  }
}
