import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss'],
})
export class TwitterComponent implements OnInit {
  isHidden: boolean = true;
  applicationName: string = 'Twitter';
  privacyThirdPartyUrl: string = 'https://twitter.com/de/privacy';

  constructor(
    private _cookieService: CookieService,
    public app: AppComponent
  ) {}

  ngOnInit(): void {
    this.app.hasAnalyticsCookie = this._cookieService.cookieStringToBoolean(
      this._cookieService.getCookie('_analytics')
    );
    this.isHidden = !this.eventIsNow();
  }

  eventIsNow(): boolean {
    const now: moment.Moment = moment();

    return now.isBetween(
      moment('2021-06-26 00:00'),
      moment('2021-06-26 23:59')
    );
  }

  activateCookies(): void {
    this._cookieService.setCookie('_analytics', 'true');
    this.app.hasAnalyticsCookie = true;
  }

  toggleState(): void {
    this.isHidden ? (this.isHidden = false) : (this.isHidden = true);
  }
}
