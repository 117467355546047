<div class="wrapper">
  <div class="social-media">
    <a
      matTooltip="{{ 'SITEMAP.SOCIAL_MEDIA.YOUTUBE' | translate }}"
      href="https://www.youtube.com/channel/UC-unDtnqge8wDJEvSxLvVWg"
      target="_blank"
    >
      <img src="assets/img/youtube_white.png" alt="Youtube" />
    </a>
    <a
      matTooltip="{{ 'SITEMAP.SOCIAL_MEDIA.INSTAGRAM' | translate }}"
      href="https://www.instagram.com/bettenrennen"
      target="_blank"
    >
      <img src="assets/img/insta_white.png" alt="Instagram" />
    </a>
    <a
      matTooltip="{{ 'SITEMAP.SOCIAL_MEDIA.FACEBOOK' | translate }}"
      href="https://www.facebook.com/BettenrennenRotgesbuttel"
      target="_blank"
    >
      <img src="assets/img/facebook.png" alt="Instagram" />
    </a>
    <a
      matTooltip="{{ 'SITEMAP.SOCIAL_MEDIA.TWITTER' | translate }}"
      href="https://www.twitter.com/bettenrennen"
      target="_blank"
    >
      <img src="assets/img/twitter_white.png" alt="Twitter" />
    </a>
  </div>
  <div class="rights">
    © {{ currentYear }} Cultura Rotlekesbutle 1887 e. V..
    <br class="mobile" />Alle Rechte vorbehalten.
  </div>
  <div class="privacy">
    <div class="open-dialog" (click)="openDialog('Imprint')">
      {{ "SITEMAP.PRIVACY.IMPRINT" | translate }}
    </div>
    <span>&nbsp;|&nbsp;</span>
    <div class="open-dialog" (click)="openDialog('Privacy')">
      {{ "SITEMAP.PRIVACY.DISCLAIMER" | translate }}
    </div>
    <span>&nbsp;|&nbsp;</span>
    <div class="open-dialog" (click)="openDialog('Transpancy')">
      Transparenz
    </div>
  </div>
</div>
