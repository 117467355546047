<div class="actionbar">
  <button mat-icon-button (click)="closeDrawer()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<h1>Impressum</h1>
<h2>Angaben gemäß § 5 TMG</h2>
<p>
  Cultura Rotlekesbutle 1887 e. V.<br />
  Schulstraße 9a<br />
  38531 Rötgesbüttel
</p>

<h2>Vertreten durch</h2>
<p>Frau Stephanie Frobese, Herrn Florian Rutsch, Herrn Oliver Thomalla<br /></p>
<h2>Kontakt</h2>
<p>
  E-Mail: <a href="cultura@rotlekesbutle.de">cultura@rotlekesbutle.de</a><br />
  Telefon: 0151 5304 1887<br />
</p>
<h2>Registereintrag</h2>
<p>
  Eintragung im Vereinsregister<br />
  Registergericht: Amtsgericht Hildesheim<br />
  Registernummer: VR 100365
</p>
<h2>Steuerbefreiung</h2>
<p>Steuernummer: 19/218/04057</p>
<p>
  Freistellungsbescheid zur Körperschaftssteuer und Gewerbesteuer vom
  27.01.2023. Laut Bescheid fördert die Körperschaft im Sinne der §§51 ff. AO
  ausschließlich und unmittelbar folgende gemeinnützige Zwecke:
</p>
<ul>
  <li>Förderung von Kunst und Kultur (§52 Abs. 2 Satz 1 Nr(n). 5 AO)</li>
</ul>
<p>
  Die Einhaltung der satzungsmäßigen Voraussetzungen nach den §§ 51, 59, 60 und
  61 AO wurde vom Finanzamt Gifhorn StNr. 19/218/04057 mit Bescheid vom
  17.01.2023 nach § 60a AO gesondert festgestellt.
</p>
<p>Wir fördern nach unserer Satzung:</p>
<ul>
  <li>Förderung der Jugend- und Altenhilfe (§52 Abs. 2 Satz 1 Nr(n). 4 AO);</li>
  <li>Förderung von Kunst und Kultur (§52 Abs. 2 Satz 1 Nr(n). 5 AO);</li>
  <li>
    Förderung der Erziehung, Volks- und Berufsbildung einschließlich der
    Studentenhilfe (§52 Abs. 2 Satz 1 Nr(n). 7 AO);
  </li>
  <li>
    Förderung der Heimatpflege, Heimatkunde und Ortsverschönerung (§52 Abs. 2
    Satz 1 Nr(n). 22 AO);
  </li>
  <li>
    Förderung des Bürgerschaftlichen Engagements zugunsten gemeinnütziger Zwecke
    (§52 Abs. 2 Satz 1 Nr(n). 25 AO)
  </li>
</ul>
<h2>Streitschlichtung</h2>
<p>
  Eine Verpflichtung an einem Streitbeilegungsverfahren vor einer
  Verbraucherschlichtungsstelle teilzunehmen besteht für uns nicht. Daher sind
  wir nicht bereit an einem solchen Verfahren teilzunehmen. Haftung für Inhalte
  Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
  Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
  wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
  gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
  forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
  Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
  Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
  erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
  Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
  Inhalte umgehend entfernen
</p>

<h2>Bildnachweise</h2>

<h3>Urheber</h3>
<p>
  <a href="https://www.enter-the-pitch.de" target="_blank"
    >Timo Binner (ETP2001)</a
  ><br /><br />
  Urheberbezeichnung erforderlich: ja<br />
  Ort der Urheberbezeichnung: auf Dokument
</p>

<h3>Urheberbezeichung wie folgt</h3>
<p>Namensnennung im Bildnachweis</p>
