<h1>Downloads</h1>
<mat-grid-list
  [cols]="breakpoint"
  rowHeight="{{ rowHeight }}"
  gutterSize="{{ gutterSize }}"
  (window:resize)="onResize($event)"
>
  <mat-grid-tile
    (click)="
      downloadFile(
        'https://www.bettenrennen.de/docs/assets/items/Bettenrennen/Ausschreibung.pdf'
      )
    "
  >
    <div class="wrapper" matRipple>
      <mat-icon>description</mat-icon>
      <h2>Ausschreibung</h2>
    </div>
  </mat-grid-tile>
  <mat-grid-tile
    (click)="
      downloadFile(
        'https://www.bettenrennen.de/docs/assets/items/Bettenrennen/Anmeldung_Team.pdf'
      )
    "
  >
    <div class="wrapper" matRipple>
      <mat-icon>description</mat-icon>
      <h2>Anmeldung Team</h2>
    </div>
  </mat-grid-tile>
  <mat-grid-tile
    (click)="
      downloadFile(
        'https://www.bettenrennen.de/docs/assets/items/Bettenrennen/uebersichtskarte.pdf'
      )
    "
  >
    <div class="wrapper" matRipple>
      <mat-icon>pin_drop</mat-icon>
      <h2>Übersichtskarte 2023</h2>
    </div>
  </mat-grid-tile>
  <mat-grid-tile
    (click)="
      downloadFile(
        'https://www.bettenrennen.de/docs/assets/items/Bettenrennen/wa_status.jpg'
      )
    "
    class=""
  >
    <div class="wrapper" matRipple>
      <mat-icon>image</mat-icon>
      <h2>Whatsapp Status</h2>
    </div>
  </mat-grid-tile>
</mat-grid-list>
