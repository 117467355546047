import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'src/app/services/cookie.service';
import * as moment from 'moment';

export interface location {
  position: number[];
  name: string;
  icon: any;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  //Map settings
  zoom: number = 18;
  lat: number = 52.41533;
  lng: number = 10.53768;

  //Pins
  scaledSize: any = { height: 32, width: 32 };
  locations: location[] = [
    // {
    //   position: [52.41429, 10.53733],
    //   name: 'Bühne',
    //   icon: {
    //     url: './assets/img/map/stage.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41444, 10.53726],
    //   name: 'Eis',
    //   icon: {
    //     url: './assets/img/map/ice.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41439, 10.53744],
    //   name: 'Bierwagen CR1887',
    //   icon: {
    //     url: './assets/img/map/beer.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41454, 10.53726],
    //   name: 'Bratwurstbude',
    //   icon: {
    //     url: './assets/img/map/sausage.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41477, 10.53731],
    //   name: 'Kuchen',
    //   icon: {
    //     url: './assets/img/map/cake.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41504, 10.53732],
    //   name: 'Cocktails & Glitzertattoos',
    //   icon: {
    //     url: './assets/img/map/cocktail.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41521, 10.53749],
    //   name: 'Hüpfburg',
    //   icon: {
    //     url: './assets/img/map/bouncing_castle.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41546, 10.53747],
    //   name: 'Holzkunstwerke',
    //   icon: {
    //     url: './assets/img/map/wooden_art.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41556, 10.53762],
    //   name: 'Waffeln',
    //   icon: {
    //     url: './assets/img/map/waffle.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41575, 10.53796],
    //   name: 'Eiswagen Luigi',
    //   icon: {
    //     url: './assets/img/map/ice_cream_van.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.416, 10.53882],
    //   name: 'Blaulicht',
    //   icon: {
    //     url: './assets/img/map/blue_light.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41625, 10.53734],
    //   name: 'Rettungsdienst',
    //   icon: {
    //     url: './assets/img/map/first_aid.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41608, 10.53776],
    //   name: 'Vereinststand CR1887',
    //   icon: {
    //     url: './assets/img/map/sale_booth.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
    // {
    //   position: [52.41605, 10.53825],
    //   name: 'Bierwagen föfurö',
    //   icon: {
    //     url: './assets/img/map/beer.png',
    //     scaledSize: this.scaledSize,
    //   },
    // },
  ];

  currentDate: string = moment().format('DD.MM.yyyy');
  infoEventDate: string = '12.05.2023';
  isInfoEvent: boolean = true;

  applicationName: string = 'Google Maps';
  privacyThirdPartyUrl: string = 'https://policies.google.com/privacy?hl=de';

  zoomLink: string =
    'https://us05web.zoom.us/j/2773540363?pwd=WHZXZUhDbDk4ZG5tcTJEOXpJVmVzQT09';

  constructor(
    private _cookieService: CookieService,
    public app: AppComponent
  ) {}

  ngOnInit(): void {
    this.app.hasAnalyticsCookie = this._cookieService.cookieStringToBoolean(
      this._cookieService.getCookie('_analytics')
    );

    this.isInfoEvent = this.currentDate == this.infoEventDate;
  }

  activateCookies(): void {
    this._cookieService.setCookie('_analytics', 'true');
    this.app.hasAnalyticsCookie = true;
  }

  openZoomSession(): void {
    window.open(this.zoomLink, '_blank');
  }
}
