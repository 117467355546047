<div class="wrapper">
  <div class="row">
    <div class="text one">
      <h1>Bettenrennen Rötgesbüttel</h1>
      <p>
        Das traditionelle Rötgesbütteler Bettenrennen findet seit 1988 alle zwei
        Jahre unter der Schirmherrschaft der Gemeinde mit Vereinen und Verbänden
        als fröhliches Straßenfest statt. Fünf Menschen und ein Bett messen sich
        in Kreativität und bei spannenden Spielen. Ganz Rötgesbüttel jubelt
        ihnen auf der Rennstrecke zu und feiert gemeinsam auf der Partymeile.
      </p>
      <p>
        Bettenrennen ist eine Veranstaltung von Rötgesbütteler*innen für
        Rötgesbütteler*innen und wird in diesem Jahr zum ersten Mal von dem
        Verein
        <a href="https://www.rotlekesbutle.de/" target="_blank"
          >Cultura Rotlekesbutle 1887 e. V.</a
        >
        veranstaltet, um den gestiegenen Anforderungen eines modernen
        Straßenfestes gerecht zu werden.
      </p>
    </div>
    <div class="img one"></div>
  </div>
  <div class="placeholder"></div>
  <div class="row">
    <div class="img two"></div>
    <div class="text two">
      <h1>Während der Corona-Pandemie war alles anders</h1>
      <p>
        Wir haben uns nicht unterkriegen lassen: Deshalb gab es im Jahr 2021 ein
        etwas anderes Bettenrennen.
      </p>
      <p>
        Die Partymeile war Euch bei zu Hause. Auf YouTube wart Ihr live dabei:
        Ein Reporterbett gegen alle Partyteams. Kreative Partys, spannende
        Spiele sowie ein Siegerteam.
      </p>
      <p>
        Das Bettenrennen 2021 kannst Du auf
        <a href="https://www.youtube.com/channel/UC-unDtnqge8wDJEvSxLvVWg"
          >Youtube</a
        >
        mit fast jedem internetfähigem Gerät sehen.
      </p>
      <!-- <p class="highlight">
        Start des Livestream am&nbsp;26.06.2021&nbsp;um&nbsp;17&nbsp;Uhr
      </p> -->
    </div>
  </div>
</div>
