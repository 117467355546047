<div class="actionbar">
  <button mat-icon-button (click)="closeDrawer()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1>Transparenzinformationen</h1>
<p>
  Transparenz ist uns wichtig. Deshalb planen wir uns der
  <a
    href="https://www.transparency.de/mitmachen/initiative-transparente-zivilgesellschaft/"
    >Initiative Transparente Zivilgesellschaft</a
  >
  anzuschließen. Wir verpflichten uns damit in Zukunft die folgenden zehn
  Informationen der Öffentlichkeit zur Verfügung zu stellen und aktuell zu
  halten.
</p>
<h2>Name, Sitz, Anschrift und Gründungsjahr</h2>
<p>
  Name:<br />
  Cultura Rotlekesbutle 1887 e. V.<br />
  Anschrift und Sitz:<br />
  Schulstraße 9a<br />
  38531 Rötgesbüttel<br />
  Gründungsjahr 1887 als "Männergesangverein Rötgesbüttel"<br />
  Der Verein wurde am 22.02.1989 als „Männergesangverein Rötgesbüttel von 1887“
  ins Vereinsregister eingetragen.<br />
  Die Umbenennung zu "Cultura Rotlekesbutle 1887" wurde am 16.12.2022 ins
  Vereinsregister eingetragen.<br />
  Registergericht: Amtsgericht Hildesheim<br />
  Registernummer: VR 100365<br />
</p>
<h2>Vollständige Satzung sowie Angaben zu den Organisationszielen</h2>
<p>
  Ziel ist die Wahrung und Weiterentwicklung der bürgerlichen Ortskultur in
  Rötgesbüttel. Wir möchten, dass unsere Heimat noch lebenswerter wird.
  Folgendes können wir uns darunter vorstellen: Eine Grillhütte, Sportgeräte,
  Musikunterricht für Kinder und Jugendliche, Freizeitmöglichkeiten für alle
  Altersgruppen. zur <a>Satzung</a><br />
</p>
<h2>Angaben zur Steuerbegünstigung</h2>
<p>
  Zuständiges Finanzamt: Gifhorn<br />
  Steuernummer: 19/218/04057<br />
  Freistellungsbescheid zur Körperschaftssteuer und Gewerbesteuer vom
  27.01.2023. Laut Bescheid fördert die Körperschaft im Sinne der §§51 ff. AO
  ausschließlich und unmittelbar folgende gemeinnützige Zwecke:<br />
  Förderung von Kunst und Kultur (§52 Abs. 2 Satz 1 Nr(n). 5 AO)<br />
  Die Einhaltung der satzungsmäßigen Voraussetzungen nach den §§ 51, 59, 60 und
  61 AO wurde vom Finanzamt Gifhorn StNr. 19/218/04057 mit Bescheid vom
  17.01.2023 nach § 60a AO gesondert festgestellt.<br />
  Wir fördern nach unserer Satzung:<br />
  Förderung der Jugend- und Altenhilfe (§52 Abs. 2 Satz 1 Nr(n). 4 AO);<br />
  Förderung von Kunst und Kultur (§52 Abs. 2 Satz 1 Nr(n). 5 AO);<br />
  Förderung der Erziehung, Volks- und Berufsbildung einschließlich der
  Studentenhilfe (§52 Abs. 2 Satz 1 Nr(n). 7 AO);<br />
  Förderung der Heimatpflege, Heimatkunde und Ortsverschönerung (§52 Abs. 2 Satz
  1 Nr(n). 22 AO);<br />
  Förderung des Bürgerschaftlichen Engagements zugunsten gemeinnütziger Zwecke
  (§52 Abs. 2 Satz 1 Nr(n). 25 AO)<br />
</p>
<h2>Name und Funktion wesentlicher Entscheidungsträger</h2>
<p>
  Der Vorstand ist als kollegialer Vorstand organisiert. Ihm gehören an:<br />
  Frau Stephanie Frobese Herr Florian Rutsch Herr Oliver Thomalla
</p>
<h2>Tätigkeitsbericht</h2>
<p>
  Im Jahre 2022 wurde 14täglich der Chorgesang gepflegt, sowie die formalen
  Voraussetzungen für die Zukunft geschaffen.
</p>
<h2>Personalstruktur</h2>
<p>
  Der Verein bestand zum 31.12.2022 aus 53 Mitgliedern, davon 3 Vorstände und 2
  Kassenprüfer. Alle Mitglieder sind ehrenamtlich tätig.
</p>
<h2>Angaben zur Mittelherkunft</h2>
<p>
  Der Verein finanziert sich über Mitgliedsbeiträge, Spenden, Zuschüsse und
  Veranstaltungen. Die Gesamteinnahmen im Jahr 2022 betrugen 887€.
</p>
<h2>Angaben zur Mittelverwendung</h2>
<p>Die Mittel werden für die Umsetzung der Satzungszwecke eingesetzt.</p>
<h2>Gesellschaftsrechtliche Verbundenheit mit Dritten</h2>
<p>Es gibt keine gesellschaftsrechtliche Verbundenheit mit Dritten.</p>
<h2>
  Namen von Personen, deren jährliche Zahlungen mehr als 10 % des
  Gesamtjahresbudgets ausmachen
</h2>
<p>
  Es gab im Jahr 2022 keine Person deren jährliche Zahlung mehr als 10% des
  Gesamtbudgets ausgemacht hat.
</p>
