import { Component, OnInit } from '@angular/core';

export interface Document {
  name: string;
  size: number;
  extention: string;
  icon: string;
  isActive: boolean;
}

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  breakpoint: number = 4;
  rowHeight: string = '1.75:1';
  gutterSize: number = 10;

  constructor() {}

  ngOnInit() {
    this.setGridSize();
  }

  onResize(event: any): void {
    this.setGridSize();
  }

  setGridSize(): void {
    if (window.innerWidth <= 1024) {
      this.breakpoint = window.innerWidth <= 1024 ? 2 : 4;
      this.rowHeight = '1:1';
    }
  }

  downloadFile(url: string): void {
    window.open(url, '_blank');
  }
}
