<div class="cookie"></div>
<form [formGroup]="formGroup">
  <div class="wrapper">
    <h2>{{ "COOKIE.NOTIFICATION.HEADLINE" | translate }}</h2>
    <p class="main-notification">
      Für technisch notwendige Funktionen, statistische Analysen und die
      Einbindung anderer Inhalte, Dienste und Elemente nutzen wir (und von uns
      verwendete Tools wie Instagram) Cookies und ähnliche Technologien.
      Weiterführende Informationen zur Verwendung von Cookies findest du in
      unseren
      <span class="span-link" (click)="openPrivacyPolicy()"
        >Datenschutzbestimmungen</span
      >.
    </p>
    <p>
      Danke für Deine Zustimmung! Über die Cookie-Konfiguration
      <mat-icon class="config">security</mat-icon> kannst du deine Auswahl
      jederzeit ändern.
    </p>
    <div class="toggle-wrapper">
      <div class="toggle">
        <mat-slide-toggle
          formControlName="cookieRequired"
          [disabled]="disabled"
        >
        </mat-slide-toggle>
      </div>
      <div>
        <div class="label">
          <div>{{ "COOKIE.NOTIFICATION.TOGGLE.REQUIRED" | translate }}</div>
        </div>
        <div class="description">
          {{ "COOKIE.NOTIFICATION.DESCRIPTION.REQUIRED" | translate }}
        </div>
      </div>
    </div>
    <div class="toggle-wrapper">
      <div class="toggle">
        <mat-slide-toggle formControlName="cookieAnalytics"></mat-slide-toggle>
      </div>
      <div>
        <div class="label">
          <div>{{ "COOKIE.NOTIFICATION.TOGGLE.THIRDPARTY" | translate }}</div>
        </div>
        <div class="description">
          {{ "COOKIE.NOTIFICATION.DESCRIPTION.THIRDPARTY" | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="btn-wrapper">
    <button class="btn primary" mat-flat-button (click)="selectAll()">
      {{ "COOKIE.NOTIFICATION.BUTTON.SELECT_ALL" | translate }}
    </button>
    <button class="btn" mat-flat-button (click)="saveSelection()">
      {{ "COOKIE.NOTIFICATION.BUTTON.SAVE_SELECTION" | translate }}
    </button>
  </div>
</form>
