<div class="header-wrapper">
  <div class="headline">
    <h1>@bettenrennen auf Instagram</h1>
  </div>
  <div class="insta-icon">
    <img src="assets/img/insta.png" alt="Instagram" (click)="openInstagram()" />
  </div>
</div>

<!-- LightWidget WIDGET -->
<div class="widget-wrapper">
  <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
  <iframe
    src="https://cdn.lightwidget.com/widgets/f92d0687dd7d593ea2c13603ad684a5b.html"
    scrolling="no"
    allowtransparency="true"
    class="lightwidget-widget"
    style="overflow: hidden !important; height: 740px"
    *ngIf="app.hasAnalyticsCookie; else blocker"
  ></iframe>

  <ng-template #blocker>
    <div class="cookie-wrapper">
      <h1>Wir möchten Dir gerne den vollen Umfang unserer Webseiten zeigen</h1>
      <p>
        Wir möchten Dir gerne unsere {{ applicationName }}-Inhalte anzeigen.
        Diese werden allerdings aufgrund Deiner aktuellen Cookie-Einstellungen
        blockiert. Klick auf “Cookies aktivieren”, um unseren
        Datenschutzbestimmungen zuzustimmen und die
        {{ applicationName }}-Inhalte anzuzeigen. Weitere Informationen erhältst
        Du in den
        <a
          class="third-party-url"
          href="{{ privacyThirdPartyUrl }}"
          target="_blank"
          >Datenschutzbestimmungen</a
        >
        von {{ applicationName }}. Du kannst der Nutzung dieser Cookies
        jederzeit in unseren Cookie-Einstellungen widersprechen.
      </p>
      <button mat-flat-button (click)="activateCookies()">
        <mat-icon>settings</mat-icon> Cookies aktivieren
      </button>
    </div>
  </ng-template>
</div>
