<mat-drawer-container [hasBackdrop]="true">
  <mat-drawer
    #drawer
    [mode]="'over'"
    position="end"
    [disableClose]="isDisabledDrawer"
    (closedStart)="drawerClosed()"
  >
    <app-cookienotification
      *ngIf="isCookieNotification"
    ></app-cookienotification>
    <app-disclaimer *ngIf="isPrivacyPolicy"></app-disclaimer>
    <app-imprint *ngIf="isImprint"></app-imprint>
    <app-transpancy *ngIf="isTranspancy"></app-transpancy>
  </mat-drawer>
  <mat-drawer-content>
    <div class="twitter">
      <app-twitter></app-twitter>
    </div>
    <div class="snap">
      <app-start></app-start>
    </div>
    <div class="snap invitation">
      <app-invitation></app-invitation>
    </div>
    <!-- <div class="snap club">
      <app-club></app-club>
    </div> -->
    <div class="snap results"><app-results></app-results></div>
    <div class="snap map"><app-map></app-map></div>
    <div class="snap documents" id="downloads">
      <app-documents></app-documents>
    </div>
    <div class="snap sponsors">
      <app-sponsors></app-sponsors>
    </div>
    <div class="snap instagram">
      <app-instagram></app-instagram>
    </div>
    <div class="snap youtube">
      <app-youtube></app-youtube>
    </div>
    <app-sitemap></app-sitemap>
  </mat-drawer-content>
</mat-drawer-container>

<button mat-mini-fab class="cookie" (click)="openDrawer('Cookie')">
  <mat-icon>security</mat-icon>
</button>
