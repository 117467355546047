import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookienotificationComponent } from './components/cookienotification/cookienotification.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { NgxTwitterTimelineComponent } from './components/ngx-twitter-timeline/ngx-twitter-timeline.component';
import { DividerComponent } from './entities/divider/divider.component';
import { DocumentsComponent } from './entities/documents/documents.component';
import { InstagramComponent } from './entities/instagram/instagram.component';
import { InvitationComponent } from './entities/invitation/invitation.component';
import { MapComponent } from './entities/map/map.component';
import { RegistrationComponent } from './entities/registration/registration.component';
import { SitemapComponent } from './entities/sitemap/sitemap.component';
import { SponsorsComponent } from './entities/sponsors/sponsors.component';
import { StartComponent } from './entities/start/start.component';
import { TwitterComponent } from './entities/twitter/twitter.component';
import { YoutubeComponent } from './entities/youtube/youtube.component';
import { MaterialModule } from './material.module';
import { CookieService } from './services/cookie.service';
import { ClubComponent } from './entities/club/club.component';
import { TranspancyComponent } from './components/transpancy/transpancy.component';
import { ResultsComponent } from './entities/results/results.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    InvitationComponent,
    RegistrationComponent,
    InstagramComponent,

    SitemapComponent,
    CountdownComponent,
    CookienotificationComponent,
    DisclaimerComponent,
    ImprintComponent,
    MapComponent,
    DividerComponent,
    SponsorsComponent,
    YoutubeComponent,
    DisclaimerComponent,
    DocumentsComponent,
    TwitterComponent,
    NgxTwitterTimelineComponent,
    ClubComponent,
    TranspancyComponent,
    ResultsComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyASXbyFFepxYqNy_Sl_D8Y_tuA9TRRgu2w',
    }),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxTwitterTimelineModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
  ],
  providers: [HttpClient, CookieService, SitemapComponent, YoutubeComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
