import { Component, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {
  constructor(private _drawer: MatDrawer) {}

  ngOnInit(): void {}

  closeDrawer(): void {
    this._drawer.close();
  }
}
