import { Component, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
  constructor(private _drawer: MatDrawer) {}

  ngOnInit(): void {}

  closeDrawer(): void {
    this._drawer.close();
  }
}
