import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {
  eventStart: moment.Moment = moment('2025-06-21 15:00');
  eventEnd: moment.Moment = moment('2025-02-21 22:00');

  duration: moment.Duration | undefined;
  rest: string[] = ['00', '00', '00', '00'];
  inEvent: boolean = false;
  afterEvent: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this._calcEventCountdown();
  }

  private _calcEventCountdown(): void {
    setInterval(() => {
      this.duration = moment.duration(this.eventStart.diff(moment()));
      if (this.duration.milliseconds() > -1) {
        this.inEvent = false;
        this.rest = [
          this._addLeader(Math.floor(this.duration.asDays())),
          this._addLeader(this.duration.hours()),
          this._addLeader(this.duration.minutes()),
          this._addLeader(this.duration.seconds()),
        ];
      } else {
        this.inEvent = true;
        moment().isSameOrAfter(this.eventEnd) &&
          ((this.afterEvent = true), (this.inEvent = false));
      }
    }, 1000);
  }

  private _addLeader(digits: number): string {
    return digits < 10 ? '0' + digits : digits.toString();
  }
}
