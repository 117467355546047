<div class="header-wrapper">
  <div class="headline">
    <h1>Live-Infostream 2023</h1>
  </div>
  <div class="youtube-icon">
    <img src="assets/img/youtube.png" alt="Youtube" (click)="openYoutube()" />
  </div>
</div>

<!-- Youtube Stream-->
<div class="video-wrapper">
  <iframe
    src="https://www.youtube.com/embed/Al8dpnPTVRQ"
    title="Live-Infostream 2023"
    frameborder="0"
    allow="autoplay; picture-in-picture"
    allowfullscreen
    *ngIf="app.hasAnalyticsCookie; else blocker"
  ></iframe>

  <ng-template #blocker>
    <div class="cookie-wrapper">
      <h1>Wir möchten Dir gerne den vollen Umfang unserer Webseiten zeigen</h1>
      <p>
        Wir möchten Dir gerne unsere {{ applicationName }}-Inhalte anzeigen.
        Diese werden allerdings aufgrund Deiner aktuellen Cookie-Einstellungen
        blockiert. Klick auf “Cookies aktivieren”, um unseren
        Datenschutzbestimmungen zuzustimmen und die
        {{ applicationName }}-Inhalte anzuzeigen. Weitere Informationen erhältst
        Du in den
        <a
          class="third-party-url"
          href="{{ privacyThirdPartyUrl }}"
          target="_blank"
          >Datenschutzbestimmungen</a
        >
        von {{ applicationName }}. Du kannst der Nutzung dieser Cookies
        jederzeit in unseren Cookie-Einstellungen widersprechen.
      </p>
      <button mat-flat-button (click)="activateCookies()">
        <mat-icon>settings</mat-icon> Cookies aktivieren
      </button>
    </div>
  </ng-template>
</div>
