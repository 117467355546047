import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss'],
})
export class InstagramComponent implements OnInit {
  applicationName: string = 'LightWidget[Instagram]';
  privacyThirdPartyUrl: string = 'https://lightwidget.com/privacy';

  constructor(
    private _cookieService: CookieService,
    public app: AppComponent
  ) {}

  ngOnInit(): void {
    this.app.hasAnalyticsCookie = this._cookieService.cookieStringToBoolean(
      this._cookieService.getCookie('_analytics')
    );
  }

  activateCookies(): void {
    this._cookieService.setCookie('_analytics', 'true');
    this.app.hasAnalyticsCookie = true;
  }

  openInstagram(): void {
    const win = window.open(
      'https://www.instagram.com/bettenrennen/',
      '_blank'
    );
  }
}
