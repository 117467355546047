import { Component, OnInit } from '@angular/core';

export interface Tile {
  img: string;
  url: string;
  alt: string;
  cols: number;
  rows: number;
}

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
})
export class SponsorsComponent implements OnInit {
  cols: number = 4;

  tiles: Tile[] = [
    {
      img: 'sponsor_2023_emmerich.png',
      url: 'https://www.emmerich-fleischerei.de/',
      alt: 'Logo Fleischerei Emmerich',
      cols: 2,
      rows: 1,
    },
    {
      img: 'sponsor_2023_edeka.png',
      url: 'https://www.edeka.de/eh/minden-hannover/edeka-center-ankermann-bahnhofstr.-13/index.jsp',
      alt: 'Logo Edeka Ankermann',
      cols: 2,
      rows: 1,
    },
    {
      img: 'sponsor_2023_fle.png',
      url: 'https://firstlocation-events.de/',
      alt: 'Logo First Location Events',
      cols: 2,
      rows: 1,
    },
    {
      img: 'sponsor_2023_baumgart.png',
      url: '',
      alt: 'Logo Cederbaum',
      cols: 2,
      rows: 1,
    },
    {
      img: 'sponsor_2023_ced.jpg',
      url: 'https://www.cederbaum.de/',
      alt: 'Logo Cederbaum',
      cols: 2,
      rows: 1,
    },
    // { img: '', url: '', alt: '', cols: 2, rows: 1 },
    {
      img: 'sponsor_2023_hellkuhl.png',
      url: 'https://www.andreas-hellkuhl.de/',
      alt: 'Logo Hellkuhl',
      cols: 1,
      rows: 1,
    },
    {
      img: 'sponsor_2023_sb.png',
      url: 'https://www.schwertrans.de/',
      alt: 'Logo SB Schwertrans',
      cols: 1,
      rows: 1,
    },
    // { img: '', url: '', alt: '', cols: 1, rows: 1 },
    // { img: '', url: '', alt: '', cols: 1, rows: 1 },
  ];

  constructor() {}

  ngOnInit(): void {
    window.innerWidth <= 1024 && (this.cols = 2);
  }

  openUrl(url: string): void {
    url !== '' && window.open(url, 'blank');
  }
}
