import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
})
export class SitemapComponent implements OnInit {
  currentYear: number = moment().year();

  constructor(public dialog: MatDialog, private _app: AppComponent) {}

  ngOnInit(): void {}

  openDialog(type: string): void {
    this._app.openDrawer(type);
  }
}
