import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CookieService {
  private analyticsCookie: boolean = false;

  constructor() {}

  setCookie(cname: string, cvalue: string): void {
    document.cookie =
      cname +
      '=' +
      cvalue +
      '; expires=' +
      moment(new Date()).add(1, 'month').toDate() +
      ';path=/';
  }

  getCookie(cname: string): string {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookie(cookie: string): boolean {
    let hasCookie: boolean = false;
    hasCookie = document.cookie.indexOf(cookie + '=') >= 0 ? true : false;
    return hasCookie;
  }
  cookieStringToBoolean(cookieValue: string): boolean {
    return cookieValue == 'true' ? true : false;
  }

  setAnalyticsCookie(analyticsCookie: boolean): void {
    this.analyticsCookie = analyticsCookie;
  }

  getAnalyticsCookie(): boolean {
    console.log(this.analyticsCookie);

    return this.analyticsCookie;
  }
}
