import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { SitemapComponent } from 'src/app/entities/sitemap/sitemap.component';
import { YoutubeComponent } from 'src/app/entities/youtube/youtube.component';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-cookienotification',
  templateUrl: './cookienotification.component.html',
  styleUrls: ['./cookienotification.component.scss'],
})
export class CookienotificationComponent {
  formGroup: FormGroup;
  disabled: boolean = true;

  constructor(
    private _cookie: CookieService,
    private _formBuilder: FormBuilder,
    private _app: AppComponent,
    private _sitemap: SitemapComponent,
  ) {
    this.formGroup = _formBuilder.group({
      cookieAnalytics: this.getCookieValue(),
      cookieRequired: true,
    });
  }

  getCookieValue(): boolean {
    let cookieValue;
    this._cookie.getCookie('_analytics') == 'true'
      ? (cookieValue = true)
      : (cookieValue = false);
    return cookieValue;
  }

  saveSelection(): void {
    this._cookie.setCookie(
      '_required',
      this.formGroup.controls['cookieRequired'].value
    );
    this._cookie.setCookie(
      '_analytics',
      this.formGroup.controls['cookieAnalytics'].value
    );
    !this._cookie.checkCookie('_cookieNotificationDisplayed') &&
      this._cookie.setCookie('_cookieNotificationDisplayed', 'true');

    this._cookie.cookieStringToBoolean(this._cookie.getCookie('_analytics'))
      ? (this._app.hasAnalyticsCookie = true)
      : (this._app.hasAnalyticsCookie = false);

    this._app.drawer.close();
  }

  selectAll(): void {
    this._cookie.setCookie('_required', 'true');
    this._cookie.setCookie('_analytics', 'true');
    !this._cookie.checkCookie('_cookieNotificationDisplayed') &&
      this._cookie.setCookie('_cookieNotificationDisplayed', 'true');
    this._app.hasAnalyticsCookie = true;
    this._app.drawer.close();
  }

  openPrivacyPolicy(): void {
    this._app.openDrawer('Privacy');
  }
}
