<div class="wrapper">
  <h1>Eventlocation 2023</h1>
  <div class="content-wrapper">
    <div class="map">
      <agm-map
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        [disableDefaultUI]="false"
        [zoomControl]="true"
        [scrollwheel]="false"
        [mapDraggable]="false"
        [streetViewControl]="false"
        *ngIf="app.hasAnalyticsCookie; else blocker"
      >
        <agm-marker [latitude]="lat" [longitude]="lng">
          <agm-info-window [isOpen]="true" style="width: 300px">
            <div class="info-wrapper">
              <img
                class="logo"
                src="../../../assets/img/logo.svg"
                alt="Logo Bettenrennenverein"
              />
              <div class="txt">
                <h2 class="headline">Bettenrennen 2023</h2>
                <p>
                  In den Straßen <span>Hinter den Wiesen</span>,
                  <span>Össelkämpe</span> und <span>Masch</span> in
                  Rötgesbüttel.
                </p>
                <p>
                  <a
                    href="https://www.google.com/maps?saddr=My+Location&daddr={{
                      lat
                    }},{{ lng }}"
                    target="_blank"
                    >Zum Routenplaner</a
                  >
                </p>
              </div>
            </div>
          </agm-info-window>
        </agm-marker>
        <div *ngFor="let location of locations">
          <agm-marker
            [latitude]="location.position[0]"
            [longitude]="location.position[1]"
            [iconUrl]="location.icon"
          >
            <agm-info-window>{{ location.name }}</agm-info-window>
          </agm-marker>
        </div>
      </agm-map>

      <ng-template #blocker>
        <div class="cookie-wrapper">
          <h1>
            Wir möchten Dir gerne den vollen Umfang unserer Webseiten zeigen
          </h1>
          <p>
            Wir möchten Dir gerne unsere {{ applicationName }}-Inhalte anzeigen.
            Diese werden allerdings aufgrund Deiner aktuellen
            Cookie-Einstellungen blockiert. Klick auf “Cookies aktivieren”, um
            unseren Datenschutzbestimmungen zuzustimmen und die
            {{ applicationName }}-Inhalte anzuzeigen. Weitere Informationen
            erhältst Du in den
            <a
              class="third-party-url"
              href="{{ privacyThirdPartyUrl }}"
              target="_blank"
              >Datenschutzbestimmungen</a
            >
            von {{ applicationName }}. Du kannst der Nutzung dieser Cookies
            jederzeit in unseren Cookie-Einstellungen widersprechen.
          </p>
          <button mat-flat-button (click)="activateCookies()">
            <mat-icon>settings</mat-icon> Cookies aktivieren
          </button>
        </div>
      </ng-template>
    </div>
    <!-- <div class="info">
      <h2>
        Infoveranstaltung für alle Anwohner:innen und Interessent:innen des
        Bettenrennen
      </h2>
      <h3>Worum gehts?</h3>
      <p>
        Wir beantworten all Eure Fragen und stimmen die letzten Details
        gemeinsam ab.
      </p>
      <h3>Wann und wo?</h3>
      <p>
        Am <span>12.&nbsp;Mai&nbsp;2023</span> um <span>19.00&nbsp;Uhr</span> in
        der <span>Bürgerhalle Rötgesbüttel</span>.
      </p>
      <h3>Videokonferenz</h3>
      <p>
        Falls Ihr nicht persönlich teilnehmen könnt: Eine Online-Teilnahme per
        Videokonferenz ist möglich. Die Zugangsdaten stehen Euch an dieser
        Stelle am 12.05.2023 zur Verfügung.
      </p>
      <p *ngIf="isInfoEvent">
        Meeting-ID: 277 354 0363<br />
        Kenncode: hmmU54
      </p>

      <div class="btn-wrapper" *ngIf="isInfoEvent">
        <button
          mat-flat-button
          class="btn-conference"
          (click)="openZoomSession()"
        >
          Zur Videokonferenz
        </button>
      </div>
    </div> -->
  </div>
</div>
