<ngx-twitter-timeline
  class="ngx-twitter"
  [data]="{
    sourceType: 'url',
    url: 'https://twitter.com/bettenrennen'
  }"
  [opts]="{
    chrome: ['nofooter'],
    tweetLimit: 5
  }"
></ngx-twitter-timeline>
